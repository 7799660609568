import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Grid,
    Heading,
    Text,
    Button,
    IconButton,
    Input,
    Spinner,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    VStack,
    Select,
    FormControl,
    Flex,
} from "@chakra-ui/react";
import {
    DownloadIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    RepeatIcon,
} from "@chakra-ui/icons";
import { FaFilePdf, FaFileExcel, FaFileWord, FaFile } from "react-icons/fa";

const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc";
const GATEWAY_API_URL =
    "https://digitalfields.co.za/Gateway/rgeneng/qa/ws1.cfc";

const getIcon = (sourceDataType) => {
    switch (sourceDataType) {
        case "PDF":
            return FaFilePdf;
        case "DOC":
        case "DOCX":
            return FaFileWord;
        case "XLS":
        case "XLSX":
            return FaFileExcel;
        default:
            return FaFile;
    }
};

const Candidate = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [candidate, setCandidate] = useState(null);
    const [previousReports, setPreviousReports] = useState(null);
    const [reportUploadOptions, setReportUploadOptions] = useState([]);
    const [frameworks, setFrameworks] = useState([]);
    const selectedFrameworkRef = useRef(undefined);
    const [draftReports, setDraftReports] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState("");
    const [loading, setLoading] = useState(true);
    const [reportsLoading, setReportsLoading] = useState(true);
    const toast = useToast();
    const [loadingIndex, setLoadingIndex] = useState(null);
    const [fileUploading, setFileUploading] = useState({
        xls: false,
        pdf: false,
    });
    const fileXLSRef = useRef(null);
    const filePDFRef = useRef(null);

    const HuserID = localStorage.getItem("HuserID");
    const UUID = localStorage.getItem("UUID");
    const HclientID = "1"; // This will need to become dynamic eventually but I've hardcoded for now

    const [captureScreenOptions, setCaptureScreenOptions] = useState([]);
    const [selectedCaptureScreen, setSelectedCaptureScreen] = useState("");
    const [selectedScreenDescription, setSelectedScreenDescription] =
        useState("");

    const fetchData = useCallback(
        async (url, errorMessage) => {
            try {
                const response = await fetch(url);
                if (!response.ok)
                    throw new Error(`HTTP error! status: ${response.status}`);
                return response.json();
            } catch (error) {
                console.error(errorMessage, error);
                toast({
                    title: "Error",
                    description: errorMessage,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                throw error;
            }
        },
        [toast]
    );

    const refreshData = useCallback(async () => {
        try {
            setLoading(true);
            setReportsLoading(true);

            if (selectedFrameworkRef.current) {
                // Fetch draft reports
                const reportsData = await fetchData(
                    `${API_BASE_URL}?method=getReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}&HframeworkID=${selectedFrameworkRef.current}`,
                    "Error fetching draft reports"
                );

                setDraftReports(reportsData);
            }

            // Create an array of promises for all the data fetches
            const [candidateData, candidateReports] = await Promise.all([
                // Fetch candidate details and assessments
                fetchData(
                    `${API_BASE_URL}?method=getcandidateDets&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
                    "Error fetching candidate details"
                ),
                // Fetch previous reports
                fetchData(
                    `${API_BASE_URL}?method=getExistingReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
                    "Error fetching candidate reports"
                ),
            ]);

            // Update all state at once
            setCandidate(candidateData[0]); // This includes the updated assessments
            setPreviousReports(candidateReports);
        } catch (error) {
            // Errors already handled in fetchData
        } finally {
            setLoading(false);
            setReportsLoading(false);
        }
    }, [HuserID, UUID, id, fetchData]);

    // Fetch report upload options
    useEffect(() => {
        const fetchReportOptions = async () => {
            try {
                const options = await fetchData(
                    `${GATEWAY_API_URL}?method=reportUploadOptions&HuserID=${HuserID}&UUID=${UUID}&HclientID=${HclientID}`,
                    "Error fetching report upload options"
                );
                setReportUploadOptions(options);
                if (options.length > 0) {
                    setSelectedReportType(options[0].uploadMethod);
                }
            } catch (error) {
                // Error already handled in fetchData
            }
        };

        fetchReportOptions();
    }, [HuserID, UUID, fetchData, HclientID]);

    // Initial data fetch
    useEffect(() => {
        refreshData();
    }, [refreshData]);

    useEffect(() => {
        const fetchCandidateDetails = async () => {
            try {
                const candidateData = await fetchData(
                    `${API_BASE_URL}?method=getcandidateDets&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
                    "Error fetching candidate details"
                );
                setCandidate(candidateData[0]);

                const candidateReports = await fetchData(
                    `${API_BASE_URL}?method=getExistingReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
                    "Error fetching candidate reports"
                );

                setPreviousReports(candidateReports);
            } catch (error) {
                // Error already handled in fetchData
            } finally {
                setLoading(false);
                setReportsLoading(false);
            }
        };

        fetchCandidateDetails();
    }, [id, HuserID, UUID, fetchData]);

    useEffect(() => {
        const fetchCaptureScreenOptions = async () => {
            try {
                const options = await fetchData(
                    `${API_BASE_URL}?method=DDcaptureScreenOptions&huserID=${HuserID}&UUID=${UUID}`,
                    "Error fetching capture screen options"
                );
                setCaptureScreenOptions(options);
                if (options.length > 0) {
                    setSelectedCaptureScreen(options[0].captureScreenRoute);
                    setSelectedScreenDescription(
                        options[0].captureScreenDescription
                    );
                }
            } catch (error) {
                // Error already handled in fetchData
            }
        };

        // Fetch list of framework options for select input
        fetchFrameworks();
        fetchCaptureScreenOptions();
    }, [HuserID, UUID, fetchData]);

    const fetchDraftReports = async (framework) => {
        if (selectedFrameworkRef.current) {
            setReportsLoading(true);
            try {
                const reportsData = await fetchData(
                    `${API_BASE_URL}?method=getReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}&HframeworkID=${framework}`,
                    "Error fetching draft reports"
                );

                setDraftReports(reportsData);
            } catch (error) {
                // Error already handled in fetchData
            } finally {
                setReportsLoading(false);
            }
        } else {
            return;
        }
    };

    const fetchFrameworks = async () => {
        setReportsLoading(true);

        try {
            const frameworksData = await fetchData(
                `${API_BASE_URL}?method=DDframeworks&huserid=${HuserID}&candidateID=${id}&UUID=${UUID}`,
                "Error fetching frameworks"
            );

            // Set frameworks piece of state used to render option elements in "select a framework" input
            setFrameworks(frameworksData);
        } catch (error) {
            // Error already handled in fetchData
        } finally {
            setReportsLoading(false);
        }
    };

    // useEffect(() => {
    //     const fetchDraftReports = async () => {
    //         try {
    //             const reportsData = await fetchData(
    //                 `${API_BASE_URL}?method=getReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}&HframeworkID=${2}`,
    //                 "Error fetching draft reports"
    //             );
    //             setDraftReports(reportsData);
    //         } catch (error) {
    //             // Error already handled in fetchData
    //         } finally {
    //             setReportsLoading(false);
    //         }
    //     };

    //     fetchDraftReports();
    // }, [id, HuserID, UUID, fetchData]);

    const handleBack = useCallback(() => {
        navigate("/candidates", {
            state: { scrollPosition: location.state?.scrollPosition },
        });
    }, [navigate, location.state?.scrollPosition]);

    const handleFileUpload = useCallback(
        async (file, fileType) => {
            if (!file) {
                toast({
                    title: "No file selected",
                    description: "Please select a file to upload.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }

            if (fileType === "pdf" && !selectedReportType) {
                toast({
                    title: "No report type selected",
                    description:
                        "Please select a report type before uploading.",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }

            setFileUploading((prev) => ({ ...prev, [fileType]: true }));

            const formData = new FormData();
            formData.append("uploadfile", file);

            const uploadUrl =
                fileType === "xls"
                    ? `${API_BASE_URL}?method=uploadScoresSheet&huserID=${HuserID}&UUID=${UUID}&hclientID=${HclientID}&CandidateID=${id}`
                    : `${API_BASE_URL}?method=${selectedReportType}&huserID=${HuserID}&hclientID=${HclientID}&CandidateID=${id}&UUID=${UUID}`;

            try {
                const response = await fetch(uploadUrl, {
                    method: "POST",
                    body: formData,
                });
                if (!response.ok) {
                    const errorDetails = await response.json();
                    throw new Error(
                        `Network response was not ok, ${errorDetails.message} (Status: ${response.status})`
                    );
                }

                toast({
                    title: "Upload successful",
                    description: `The ${
                        fileType === "xls" ? "spreadsheet" : "report"
                    } has been uploaded successfully.`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });

                // Reset file input
                if (fileType === "xls") fileXLSRef.current.value = "";
                if (fileType === "pdf") filePDFRef.current.value = "";

                // Immediately refresh the data
                await refreshData();
            } catch (error) {
                console.error("Upload failed:", error);
                toast({
                    title: "Upload failed",
                    description: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setFileUploading((prev) => ({ ...prev, [fileType]: false }));
            }
        },
        [HuserID, UUID, id, toast, selectedReportType, HclientID, refreshData]
    );

    const handleUploadSpreadsheet = useCallback(() => {
        const file = fileXLSRef.current.files[0];
        handleFileUpload(file, "xls");
    }, [handleFileUpload]);

    const handleUploadReportPdf = useCallback(() => {
        const file = filePDFRef.current.files[0];
        handleFileUpload(file, "pdf");
    }, [handleFileUpload]);

    const handleDraftDownload = useCallback(
        async (report, index) => {
            setLoadingIndex(index);
            try {
                if (!report.genDraftMethod) {
                    throw new Error(
                        "No draft generation method specified for this report"
                    );
                }

                const baseUrl = report.genDraftMethod;
                const separator = baseUrl.includes("?") ? "&" : "?";
                const url = `${baseUrl}${separator}candidateid=${id}&hreportID=${report.HreportID}&UUID=${UUID}`;

                window.open(url, "_blank");

                // Wait a moment for the report to be generated, then refresh
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await refreshData();
            } catch (error) {
                console.error("Error generating the draft report", error);
                toast({
                    title: "Draft Generation Failed",
                    description:
                        error.message ||
                        "There was an error generating the draft report.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
            setLoadingIndex(null);
        },
        [id, UUID, toast, refreshData]
    );

    const handleDownload = useCallback(
        async (
            allocHreportHuserID,
            finalVersion,
            generateFinalDocument,
            HreportID
        ) => {
            const loadingKey = `${allocHreportHuserID}-${
                finalVersion === 1 ? "final" : "draft"
            }`;
            setLoadingIndex(loadingKey);

            try {
                let url;
                if (generateFinalDocument) {
                    const separator = generateFinalDocument.includes("?")
                        ? "&"
                        : "?";
                    url = `${generateFinalDocument}${separator}HuserID=${HuserID}&UUID=${UUID}&HreportID=${HreportID}&CandidateID=${id}`;
                } else {
                    url = `${API_BASE_URL}?method=downloadreport&allocHreportHuserID=${allocHreportHuserID}&finalVersion=${finalVersion}&UUID=${UUID}&HuserID=${HuserID}`;
                }
                window.open(url, "_blank");

                // Wait a moment for the report to be generated/downloaded, then refresh
                await new Promise((resolve) => setTimeout(resolve, 2000));
                await refreshData();
            } catch (error) {
                console.error("Error downloading/generating the report", error);
                toast({
                    title: generateFinalDocument
                        ? "Generation failed"
                        : "Download failed",
                    description: `There was an error ${
                        generateFinalDocument ? "generating" : "downloading"
                    } the report.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
            setLoadingIndex(null);
        },
        [UUID, HuserID, toast, id, refreshData]
    );

    //Two new functions to handle capture screen option selection and navigation

    const handleCaptureScreenChange = (e) => {
        const selectedOption = captureScreenOptions.find(
            (option) => option.captureScreenRoute === e.target.value
        );
        setSelectedCaptureScreen(e.target.value);
        setSelectedScreenDescription(
            selectedOption?.captureScreenDescription || ""
        );
    };

    const handleNavigateToCapture = () => {
        if (selectedCaptureScreen) {
            const selectedOption = captureScreenOptions.find(
                (option) => option.captureScreenRoute === selectedCaptureScreen
            );

            if (selectedOption?.captureScreenName === "9 box grid") {
                navigate("/performance100", {
                    state: {
                        candidateId: id,
                        candidateDets: candidateDets, // Pass the candidate details
                    },
                });
            } else {
                navigate(`/${selectedCaptureScreen}`, {
                    state: {
                        candidateId: id,
                        candidateDets: candidateDets,
                    },
                });
            }
        }
    };

    const CollapsibleSection = ({ title, children }) => {
        const [isOpen, setIsOpen] = useState(true);

        return (
            <Box>
                <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => setIsOpen(!isOpen)}
                    mb={4}
                >
                    <Heading size="md" flex="1">
                        {title}
                    </Heading>
                    <IconButton
                        icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        variant="ghost"
                        aria-label={
                            isOpen ? "Collapse section" : "Expand section"
                        }
                        size="sm"
                    />
                </Flex>
                <Box display={isOpen ? "block" : "none"}>{children}</Box>
            </Box>
        );
    };

    if (loading || reportsLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Spinner size="xl" />
            </Box>
        );
    }

    if (!candidate) {
        return (
            <Box p={5}>
                <Button onClick={handleBack} mb={5}>
                    Back to Candidates
                </Button>
                <Heading mb={5}>Candidate Details</Heading>
                <Text>No candidate data available.</Text>
            </Box>
        );
    }
    let { candidateDets, assessments } = candidate;
    candidateDets = candidateDets[0];

    return (
        <Box p={6}>
            <Button onClick={handleBack} mb={5}>
                Back to Candidates
            </Button>

            <Grid templateColumns="2fr 1fr" gap={6}>
                {/* Left Column */}
                <VStack align="stretch" spacing={6}>
                    {/* Candidate Details Section */}
                    <Box border="1px" borderColor="gray.200" p={4}>
                        <Table variant="simple">
                            <Tbody>
                                <Tr>
                                    <Td fontWeight="bold" width="150px">
                                        Name
                                    </Td>
                                    <Td>{`${candidateDets.fname} ${candidateDets.sname}`}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontWeight="bold">Email</Td>
                                    <Td>{candidateDets.email}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontWeight="bold">Employee number</Td>
                                    <Td>{candidateDets.employeeNo || "N/A"}</Td>
                                </Tr>
                                <Tr>
                                    <Td fontWeight="bold">Active</Td>
                                    <Td>
                                        {candidateDets.activeYN ? "Yes" : "No"}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>

                    {/* Assessments Section */}
                    <CollapsibleSection title="Assessments">
                        <Table
                            variant="simple"
                            border="1px"
                            borderColor="gray.200"
                        >
                            <Thead>
                                <Tr>
                                    <Th>Assessment name</Th>
                                    <Th>Upload date</Th>
                                    <Th>Uploader</Th>
                                    <Th>Source</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {assessments &&
                                    assessments.map((assessment, index) => (
                                        <Tr key={index}>
                                            <Td>{assessment.displayName}</Td>
                                            <Td>
                                                {assessment.uploadDate || "N/A"}
                                            </Td>
                                            <Td>
                                                {assessment.uploadedBy || "N/A"}
                                            </Td>
                                            <Td>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    gap={2}
                                                >
                                                    <Box
                                                        as={getIcon(
                                                            assessment.sourceDataType
                                                        )}
                                                    />
                                                    <Text>
                                                        {assessment.sourceDataType ||
                                                            "N/A"}
                                                    </Text>
                                                </Box>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </CollapsibleSection>

                    {/* Reports Section */}
                    <CollapsibleSection title="Reports">
                        <Table
                            variant="simple"
                            border="1px"
                            borderColor="gray.200"
                        >
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Final</Th>
                                    <Th>Draft</Th>
                                    <Th>Description</Th>
                                    <Th>Date</Th>
                                    <Th>Generated by</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {previousReports &&
                                    previousReports.map((report) => (
                                        <Tr key={report.allocHreportHuserID}>
                                            <Td>{report.reportName}</Td>
                                            <Td>
                                                <IconButton
                                                    icon={
                                                        report.FinalDocumentYN ===
                                                        1 ? (
                                                            <DownloadIcon />
                                                        ) : (
                                                            <RepeatIcon />
                                                        )
                                                    }
                                                    onClick={() =>
                                                        report.FinalDocumentYN ===
                                                        1
                                                            ? handleDownload(
                                                                  report.allocHreportHuserID,
                                                                  1
                                                              )
                                                            : handleDownload(
                                                                  report.allocHreportHuserID,
                                                                  null,
                                                                  report.generateFinalDocument,
                                                                  report.HreportID
                                                              )
                                                    }
                                                    isLoading={
                                                        loadingIndex ===
                                                        `${report.allocHreportHuserID}-final`
                                                    }
                                                    aria-label={
                                                        report.FinalDocumentYN ===
                                                        1
                                                            ? "Download final report"
                                                            : "Generate final report"
                                                    }
                                                />
                                            </Td>
                                            <Td>
                                                <IconButton
                                                    icon={<DownloadIcon />}
                                                    onClick={() =>
                                                        handleDownload(
                                                            report.allocHreportHuserID,
                                                            0
                                                        )
                                                    }
                                                    isLoading={
                                                        loadingIndex ===
                                                        `${report.allocHreportHuserID}-draft`
                                                    }
                                                    aria-label="Download draft report"
                                                />
                                            </Td>
                                            <Td>
                                                {report.reportDescription ||
                                                    "N/A"}
                                            </Td>
                                            <Td>
                                                {report.generatedOn || "N/A"}
                                            </Td>
                                            <Td>
                                                {report.generatedBy || "N/A"}
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </CollapsibleSection>
                </VStack>

                {/* Right Column */}
                <VStack align="stretch" spacing={6}>
                    {/* New Capture Screens Section */}
                    <Box border="1px" borderColor="gray.200" p={4}>
                        <Heading size="md" mb={4}>
                            Capture Scores
                        </Heading>
                        <FormControl>
                            <Select
                                value={selectedCaptureScreen}
                                onChange={handleCaptureScreenChange}
                                mb={2}
                            >
                                <option value="">
                                    Select a capture screen
                                </option>
                                {captureScreenOptions.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.captureScreenRoute}
                                    >
                                        {option.captureScreenName}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Not sure if we should include a description here as report uploads etc do not use this */}
                        {selectedScreenDescription && (
                            <Text mb={2} fontSize="sm" color="gray.600">
                                {selectedScreenDescription}
                            </Text>
                        )}
                        <Button
                            width="full"
                            onClick={handleNavigateToCapture}
                            isDisabled={!selectedCaptureScreen}
                            // colorScheme="blue"
                        >
                            {/* Discuss the naming of this button */}
                            Go to Capture Screen
                        </Button>
                    </Box>
                    {/* Upload Reports Section */}
                    <Box border="1px" borderColor="gray.200" p={4}>
                        <Heading size="md" mb={4}>
                            Upload reports
                        </Heading>
                        <FormControl>
                            <Select
                                value={selectedReportType}
                                onChange={(e) =>
                                    setSelectedReportType(e.target.value)
                                }
                                mb={2}
                            >
                                {reportUploadOptions.map((option, index) => (
                                    <option
                                        key={index}
                                        value={option.uploadMethod}
                                    >
                                        {option.displayName}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <Input
                            type="file"
                            ref={filePDFRef}
                            accept=".pdf"
                            mb={2}
                        />
                        <Button
                            width="full"
                            onClick={handleUploadReportPdf}
                            isLoading={fileUploading.pdf}
                        >
                            Upload
                        </Button>
                    </Box>

                    {/* Upload Spreadsheet Section */}
                    <Box border="1px" borderColor="gray.200" p={4}>
                        <Heading size="md" mb={4}>
                            Upload spreadsheet
                        </Heading>
                        <Input
                            type="file"
                            ref={fileXLSRef}
                            accept=".xls,.xlsx"
                            mb={2}
                        />
                        <Button
                            width="full"
                            onClick={handleUploadSpreadsheet}
                            isLoading={fileUploading.xls}
                        >
                            Upload
                        </Button>
                    </Box>

                    {/* Draft Reports Section */}
                    <Box border="1px" borderColor="gray.200" p={4}>
                        <Heading size="md" mb={4}>
                            Choose Framework
                        </Heading>
                        <FormControl>
                            {/* Select framework setting the setFrameworks state
                            Then generate draft reports using getReportsForCandidate */}
                            <Select
                                value={selectedFrameworkRef.current}
                                onChange={(e) => (
                                    (selectedFrameworkRef.current =
                                        e.target.value),
                                    fetchDraftReports(e.target.value)
                                )}
                                mb={2}
                            >
                                <option value={undefined}>
                                    Select a framework
                                </option>
                                {frameworks.map((framework) => {
                                    return (
                                        <option
                                            key={framework.HframeworkID}
                                            value={framework.HframeworkID}
                                        >
                                            {framework.description}
                                        </option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <VStack align="stretch" spacing={2}>
                            {draftReports &&
                                draftReports.map((report, index) => (
                                    <Button
                                        key={index}
                                        variant="link"
                                        leftIcon={<RepeatIcon />}
                                        onClick={() =>
                                            handleDraftDownload(report, index)
                                        }
                                        isLoading={loadingIndex === index}
                                        isDisabled={!report.genDraftMethod}
                                    >
                                        {report.reportName}
                                    </Button>
                                ))}
                        </VStack>
                    </Box>
                </VStack>
            </Grid>
        </Box>
    );
};

export default Candidate;
